import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				 MotoMystique
			</title>
			<meta name={"description"} content={"Unravel the Road's Secrets"} />
			<meta property={"og:title"} content={" MotoMystique"} />
			<meta property={"og:description"} content={"Unravel the Road's Secrets"} />
			<meta property={"og:image"} content={"https://liltokoy.com/img/bike_2.jpg"} />
			<link rel={"shortcut icon"} href={"https://liltokoy.com/img/3824290.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileImage"} content={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileColor"} content={"https://liltokoy.com/img/3824290.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Privacy Policy
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Your privacy is important to us. This privacy policy explains how we collect, use, disclose and protect your personal information. Please read this privacy policy carefully.
				<br />
				<br />
				<Strong>
					WHAT PERSONAL DATA DO WE COLLECT?
				</Strong>
				<br />
				We may collect information such as your name, email address, phone number and other relevant contact details when you use our Services or communicate with us.
				<br />
				<br />
				<Strong>
					HOW DO WE USE YOUR DATA?
				</Strong>
				<br />
				We use your personal information to process your requests, manage reservations and provide you with information about our services.
				<br />
				<br />
				<Strong>
					HOW DO WE PROTECT YOUR DATA?
				</Strong>
				<br />
				We take appropriate security measures to protect your personal information from unauthorized access, modification or disclosure.
				<br />
				<br />
				<Strong>
					DISCLOSURE OF INFORMATION
				</Strong>
				<br />
				We do not share your personal information with third parties unless required by law or necessary to provide our services.
				<br />
				<br />
				<Strong>
					CHANGES TO THIS PRIVACY POLICY
				</Strong>
				<br />
				This privacy policy may be updated from time to time. Please check our privacy policy regularly for changes.
				<br />
				<br />
				<Strong>
					CONTACT US
				</Strong>
				<br />
				If you have any questions or concerns about the privacy policy, please contact us at contact@liltokoy.com
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});